@import url("https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans:wght@700&display=swap");
* {
  font-family: "Lato" !important;
}
body {
  font-family: "Lato" !important;
}
.css-10hburv-MuiTypography-root {
  font-family: "Lato" !important;
}
div.MuiBox-root {
  font-family: "Lato" !important;
}

/* Add global styles for input labels */
.MuiInputLabel-root {
  font-family: "Lato" !important;
}

#myButton .MuiButton-label {
  font-family: "Lato" !important;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track:hover {
  background: #ccc;
}
.label_settings {
  font-size: 16px;
  font-weight: 600;
}
.textField_settings {
  height: 49px;
  border-radius: 8px;
  border: 1px solid #E5E5E5,
}

#date-picker-inline{
  width:100px !important
}


#materialUiDatePicker .MuiInputAdornment-positionEnd button{
 padding: 0px !important;
}
.materialUiDatePicker .MuiInputAdornment-positionEnd button{
 padding: 0px !important;
}
