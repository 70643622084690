.heading {
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
}

.font {
  font-family: "Poppins", sans-serif !important;
}

.chat__time {
  font-family: "Poppins", sans-serif !important;
  font-size: 11px !important;
  align-self: end;
  /* padding-right: 90px; */
}

.chat__container {
  margin: 1rem 0;
  background-color: white;
  border-radius: 4px;
  /* height: 100%; */
}

.border {
  border: 2px solid black;
}

.sticky__div {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.chat__fullcontainer {
  margin-top: 0.3rem;
  border-radius: 12px;
  min-height: 65px;
  width: 100%;
  padding: 12px;
  flex-direction: row !important;
  align-items: start !important;
}

.chat__singlecontainer {
  flex-direction: row !important;
  align-items: center !important;
  gap: 20px;
}

.chat__gridcontainer {
  padding: 0 !important;
}

.space-between {
  padding: 0 1.2rem;
}

.recievermsg {
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px 8px 16px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 12px;
  max-width: 50%;
  word-break: break-all !important;
  line-break: anywhere;
  overflow-wrap: break-word;
  box-shadow: 0px 8px 18px 0px #00000014;

  /* max-width: 50%; */
  /* display: flex;
    flex-direction: column;
    align-self: center; */

  gap: 10px;
  margin: 10px
    /* width: 100%; */
}

.sendermsg {
  background-color: #fff !important;
  color: black !important;
  font-weight: 500;

}

.scrollsmall::-webkit-scrollbar {
  width: 8px;
}

.scrollsmall::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollsmall::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollsmall::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.document {
  padding: 12px;
  /* background-color: #d1f4cc; */
  flex-direction: row !important;
  align-items: center !important;
  gap: 1rem;
  justify-content: space-between;
}

.document__download,
.document__ext {
  width: 34px;
  height: 34px;
}

.date__time--order {
  align-items: center;
  align-self: center;
  font-family: "Poppins", sans-serif !important;
  padding: 5px 12px 6px;

  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  background-color: hsla(0, 0%, 100%, 0.95);
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  margin: 1rem 0;
  font-size: 12.5px;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

@media (max-width: 1100px) {
  .space-between {
    padding: 0 0.3rem !important;
  }

  .sidebar__details {
    width: 120px;
    overflow: hidden;
  }
}

.epr-preview {
  display: none !important;
}